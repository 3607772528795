define("discourse/plugins/discourse-dev-additions/discourse/connectors/user-custom-preferences/employee-contract-start-date", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = dt7948.p({
    setupComponent(args, component) {
      component.set("employee_contract_start_date", args.model.custom_fields.employee_contract_start_date);
    },
    shouldRender(args, component) {
      return component.siteSettings.dev_additions_enabled;
    },
    onChangeDate(date) {
      this.model.set("custom_fields.employee_contract_start_date", date?.format("YYYY-MM-DD") || "");
    }
  }, [["method", "onChangeDate", [_object.action]]]);
});