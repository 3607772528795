define("discourse/plugins/discourse-dev-additions/discourse/connectors/above-user-summary-stats/holidays-outlet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    shouldRender(attrs, component) {
      return attrs.user && component.siteSettings.dev_additions_enabled;
    }
  };
});